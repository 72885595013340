.img__body{
  display: flex;
  position: relative;

}
.img,
.img2{
  width: 300px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  height: 600px;
}
.img2{
  position: absolute;
  top:50px;
  left: 320px;
}
.text{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text h5{
  font-weight: bold;
  font-size: 40px;
}
.text p{
  width: 300px;
  font-size: 17px;
}
.down{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.down img{
  width: 320px;
}